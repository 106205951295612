@import '../node_modules/modern-normalize/modern-normalize.css';

html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
  }

/* Обнуляем заглавления */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
/* обнуляем, убираем маркировку списка */
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
/* обнуляем р */
p {
  margin: 0;
}
/* убираем стиль ссилок */
a {
  /* убираем подчёркивания */
  text-decoration: none;
  /* убираем курсив */
  font-style: normal;
  /* добавляем наследование */
  color: inherit;
  font-family: inherit;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}
