.container {
}
.table {
	display: block;
}
.cell {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	white-space: break-spaces;
	border: 1px solid #000000;
	font-size: 14px;
}
.tableHead {
	display: flex;
}
.titleTable {
	background-color: rgb(28, 194, 132);
	font-weight: bold;
}
// width column for table
.searchCode {
	@extend .cell;
	min-width: 200px;
	max-width: 200px; // код(50px) + фото(150px)
	& .input {
		width: 100%;
		padding: 0.375rem 0.75rem;
		font-size: 21px;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
	}
}
.cellCode {
	@extend .cell;
	@extend .titleTable;
	min-width: 50px;
	max-width: 50px;
}
.cellDescription {
	@extend .cell;
	text-align: center;
	min-width: 200px;
	max-width: 200px;
}
.cellSizes {
	@extend .cell;
	min-width: 135px;
	max-width: 135px;
}
.cellPrice {
	@extend .cell;
	text-align: center;
	min-width: 70px;
	max-width: 70px;
	font-size: 32px;
}
.cellPriceHeader {
	@extend .cell;
	text-align: center;
	min-width: 70px;
	max-width: 70px;
}
.cellArrivdesc {
	@extend .cell;
	text-align: center;
	min-width: 150px;
	max-width: 150px;
}
.cellSize {
	@extend .cell;
	min-width: 120px;
	max-width: 120px;
}
.cellDateRefresh {
	@extend .cell;
	min-width: 1355px;
	max-width: 1355px;
}
.cellImg {
	@extend .cell;
	padding: 0;
	min-width: 150px;
	max-width: 150px;
}
.rowTable {
	display: flex;
	min-width: 1355px;
	max-width: 1355px;
	&[selected] {
		background-color: rgb(135, 219, 188);
	}
}
.textLeft {
	text-align: left;
}
.image {
	min-height: 200px;
	max-height: 200px;
}
.sticky {
	position: sticky;
	top: 0;
}
.text-red {
	color: red;
	font-weight: bold;
}
