.mainContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 1355px;
    max-width: 1355px;
    height: 40px;
    background-color: cadetblue;
}
.btn{
    margin-left: 15px;
    padding: 8px 6px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}